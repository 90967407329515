/* eslint-disable react/require-default-props */
import { DIMENSIONS } from '@canalplus/mycanal-commons';
import { ImageType } from '@canalplus/mycanal-sharedcomponent';
import { Template } from '@canalplus/sdk-hodor';
import type { ApiV2Cover } from '@dce-front/hodor-types/api/v2/detail/spyro/definitions';
import classNames from 'classnames/bind';
import { forwardRef } from 'react';
import Linker from '../../../../components/Linker/Linker';

import styles from './Heading.css';

const cx = classNames.bind(styles);

const BIG_TITLE_MINIMUM_LENGTH = 40;

export type HeadingProps = {
  title?: string;
  link?: string;
  cover?: ApiV2Cover;
  id?: string;
  isTvDevice: boolean;
  isLoggedIn: boolean;
};

/**
 * Render as text or clickable link within H1 tag
 */
const Heading = forwardRef<HTMLDivElement, HeadingProps>(({ title, link, cover, id, isTvDevice, isLoggedIn }, ref) => {
  const shouldRenderAsLink = !isLoggedIn && !isTvDevice && link;
  const shouldHandleBigTitle = !!title && title.length >= BIG_TITLE_MINIMUM_LENGTH;

  const logoURL = {
    default: cover?.regularImage?.URLLogotype || '',
    mobile: cover?.compactImage?.URLLogotype,
  };

  const titleContent = logoURL.default ? (
    <ImageType
      className={cx('heading__title-image')}
      altImage={title}
      URLImage={logoURL}
      dimensions={isTvDevice ? DIMENSIONS.LOGO_TITLE_V5_TV : DIMENSIONS.LOGO_TITLE_V5}
      isTvDevice={isTvDevice}
    />
  ) : (
    title
  );

  return (
    <div className={cx('heading__titleWrapper', 'heading__titleWrapper--fadeIn')}>
      <div className={cx('heading__titleStickySentinel')} ref={ref} />
      <h1
        id={id}
        className={cx('heading__title', {
          'heading__title-text': !logoURL.default,
          'heading__title-text--big': !logoURL.default && shouldHandleBigTitle,
        })}
      >
        {shouldRenderAsLink ? (
          <Linker
            data={{
              mainOnClick: {
                URLWebsite: link,
                displayTemplate: Template.ExternalSite,
                target: '_self',
              },
            }}
            title={title}
          >
            {titleContent}
          </Linker>
        ) : (
          titleContent
        )}
      </h1>
    </div>
  );
});

Heading.displayName = 'Heading';

export default Heading;
