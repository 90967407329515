import { Ratio, addQueryParam } from '@canalplus/mycanal-commons';
import { CustomParentalRating, TechnicalInfos } from '@canalplus/mycanal-sharedcomponent';
import { mapStaticKey } from '@canalplus/mycanal-util-react';
import { Binder } from '@canalplus/one-navigation';
import { PlayPauseControlButton, SoundControlButton } from '@canalplus/oneplayer-shared-components';
import { Template } from '@canalplus/sdk-hodor';
import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames/bind';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRoutingContext } from '../../../components/Page/RoutingContext';
import { FullFrameButton, Actions as VideoActions, VideoProvider } from '../../../components/Video';
import { QueryKeys } from '../../../constants/queryKeys';
import Logger from '../../../helpers/logger/logger-helper';
import { MIDDLEWARE_ACTION_LAYOUT } from '../../../helpers/oneNavigation/middleware';
import I18n from '../../../lang';
import { getFeatureToggleTrailerPreview } from '../../../store/slices/application-selectors';
import { displayTVModeSelector } from '../../../store/slices/displayMode-selectors';
import { isImmersiveSelector } from '../../../store/slices/immersive-selectors';
import {
  isPlayerOpenSelector,
  isTrailerSelector,
  playerLastCurrentTimeSelector,
  playerLastWatchedEpisodeStreamIdSelector,
} from '../../../store/slices/player-selectors';
import { authenticatedSelector } from '../../../store/slices/user-selectors';
import { useDetailContext, useDetailDispatch } from '../../../templates/DetailV5/data/provider';
import { scrollElementToTop } from '../../../templates/helpers/DetailV5-helpers';
import { getTvTabs } from '../data/helper';
import { setURLActionLayout } from '../data/store/actions';
import ActionLayout from './ActionLayout/ActionLayout';
import MoreInfosButton from './ActionLayout/MoreInfosButton/MoreInfosButton';
import MoreInfosModal from './ActionLayout/MoreInfosModal/MoreInfosModal';
import Cover from './Cover/Cover';
import styles from './DetailV5.css';
import Heading from './Heading/Heading';
import EditorialTitle from './Informations/EditorialTitle/EditorialTitle';
import Metadatas from './Informations/Metadatas/Metadatas';
import Reviews from './Informations/Reviews/Reviews';
import Summary from './Informations/Summary/Summary';
import URLLogo from './Informations/URLLogo/URLLogo';
import StickyTitle from './StickyTitle/StickyTitle';
import Tabs from './Tabs/Tabs';

const cx = classNames.bind(styles);

type DetailV5Props = {
  onFocusable?: () => void;
};

function DetailV5({ onFocusable }: DetailV5Props): JSX.Element {
  const { t } = I18n.useTranslation();
  const lastCurrentTimeVideo = useSelector(playerLastCurrentTimeSelector);
  const lastWatchedEpisodeStreamId = useSelector(playerLastWatchedEpisodeStreamIdSelector);

  const {
    actionLayout,
    meta,
    detail: {
      summary,
      reviews,
      personalities,
      cover,
      title,
      editorialTitle,
      technicalInfos,
      URLLogoChannel,
      moreInfos,
      productionNationalities,
    },
    isFunnelTvodOpened,
    nextURLActionLayout,
    tabs = [],
  } = useDetailContext();

  const from = Template.DetailPage;
  const detailDispatch = useDetailDispatch();
  const queryClient = useQueryClient();

  const isTvDevice = useSelector(displayTVModeSelector);
  const isImmersive = useSelector(isImmersiveSelector);
  const isPlayerOpen = useSelector(isPlayerOpenSelector);
  const isPlayingTrailer = useSelector(isTrailerSelector);
  const isLoggedIn = useSelector(authenticatedSelector);
  const hasTrailer = useSelector(getFeatureToggleTrailerPreview);
  const detailV5Ref = useRef<HTMLDivElement>(null);
  const mainTitleRef = useRef<HTMLDivElement | null>(null);
  const isLastPlayerTrailer = useRef<boolean>();

  const [tabIndexToScroll, setTabIndexToScroll] = useState<number | null>(null);
  const [isMoreInfosModalOpen, setOpenMoreInfosModal] = useState(false);
  const routingContext = useRoutingContext();
  /**
   * if Immersive => isActionLoading = true (fadeIn animation with perso) [also applies to TV]
   * if Page => isActionLoading = false (no fadeIn animation, and data is directly visible for noJS SSR)
   */
  const [isActionLayoutLoading, toggleActionLayoutLoading] = useState(routingContext === 'immersive');

  const refetchDetailQueries = useCallback(async () => {
    const needAddQueryParamTimecode = lastCurrentTimeVideo !== undefined;
    let newUrlActionLayout = nextURLActionLayout || actionLayout?.actionLayoutPerso?.URLPage;

    // add queryParam 'timecode' and 'lastWatchedEpisodeStreamId' to newUrlActionLayout with timecode and lastWatchedEpisodeStreamId of the last content played.
    // It's for hodor to calculate the good progression value to return in actionLayout data
    if (needAddQueryParamTimecode && newUrlActionLayout) {
      newUrlActionLayout = addQueryParam(
        newUrlActionLayout,
        'timecode',
        Math.round(Number(lastCurrentTimeVideo)).toString()
      );

      // add query param lastWatchedEpisodeStreamId only for series
      if (lastWatchedEpisodeStreamId !== undefined) {
        newUrlActionLayout = addQueryParam(
          newUrlActionLayout,
          'lastWatchedEpisodeStreamId',
          lastWatchedEpisodeStreamId.toString()
        );
      }
    }

    if (newUrlActionLayout && newUrlActionLayout !== actionLayout?.actionLayoutPerso?.URLPage) {
      // We override the URLActionLayout with the new one if different, which will provoque a react re-render of <ActionLayoyt .../> since
      // one of the props passed to it changed.
      detailDispatch(setURLActionLayout(newUrlActionLayout));
    } else {
      // If the URLActionLayout is the same as the one stored without context, we can directly call a refetch, since the useQuery Query URL
      // is the good one.
      await queryClient.refetchQueries({ queryKey: [QueryKeys.DetailActionLayout] });
    }

    await queryClient.refetchQueries({ queryKey: [QueryKeys.DetailEpisodes] });
  }, [
    queryClient,
    nextURLActionLayout,
    detailDispatch,
    actionLayout?.actionLayoutPerso?.URLPage,
    lastCurrentTimeVideo,
    lastWatchedEpisodeStreamId,
  ]);

  const compactImgRatio = cover?.compactImage?.imageRatio || Ratio.Ratio34;
  const regularImgRatio = cover?.regularImage?.imageRatio || Ratio.Ratio169;

  // Add translated titles (i18n) to parentalRatings
  const { parentalRatings } = technicalInfos || {};
  const parentalRatingsWithTitles = parentalRatings?.map(({ authority, value, ...rest }): CustomParentalRating => {
    return {
      authority,
      value,
      title: t(`ParentalRating.rating${value}`),
      ...rest,
    };
  });

  const scrollToTab = useCallback((index: number | null) => {
    setTabIndexToScroll(index);
  }, []);

  const deviceSpecificTabs = useMemo(() => {
    return isTvDevice ? getTvTabs(tabs) : tabs;
  }, [isTvDevice, tabs]);

  /**
   * When `MORE INFOS` button is clicked:
   * - On TV, open MoreInfos modal
   * - On desktop, scroll down to MORE INFOS tab
   */
  const handleOpenMoreInfo = (shouldOpenModal: boolean) => {
    if (isTvDevice && moreInfos?.URLPage) {
      return setOpenMoreInfosModal(shouldOpenModal);
    }

    if (!deviceSpecificTabs.length) {
      return;
    }

    // "MORE INFOS" is always the last Tab
    const moreInfosTabIndex = deviceSpecificTabs.length - 1;
    const { displayName } = deviceSpecificTabs[moreInfosTabIndex];
    if (!displayName) {
      return;
    }
    setTabIndexToScroll(moreInfosTabIndex);
    scrollElementToTop(displayName);
  };

  /**
   * ActionLayout Refetch logic on Player opening/closing
   */
  useEffect(() => {
    // When player opens:
    // 1. memorize in ref whether played content is of type TRAILER
    // 2. exit useEffect
    if (isPlayerOpen) {
      isLastPlayerTrailer.current = !!isPlayingTrailer;
      return;
    }

    // When player closes, trigger a refetchDetailQueries() to get perso data (such as progress data) except trailers
    if (isLastPlayerTrailer.current === false) {
      refetchDetailQueries().catch(({ errorCode, errorSummary: errorMessage }) => {
        Logger.error('DetailV5::refetchDetailQueries()', { errorCode, errorMessage });
      });
    }

    // This useEffect must be based only on the "isPlayerOpen" dep to avoid side effects such as unwanted execution of the refetch
  }, [isPlayerOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const shouldFadeIn = isTvDevice || !isActionLayoutLoading;

  return (
    <div ref={detailV5Ref} className={cx('detailV5')}>
      <StickyTitle title={title} mainTitleRef={mainTitleRef} />
      <header className={cx('detailV5__header', { 'detailV5__header-immersive': isImmersive })}>
        <VideoProvider>
          {cover && (
            <section
              className={cx(
                'detailV5__coverWrapper',
                `detailV5__coverWrapper--compact-ratio-${compactImgRatio}`,
                `detailV5__coverWrapper--regular-ratio-${regularImgRatio}`
              )}
            >
              <Cover from={from} cover={cover} title={title} isVisible={!isMoreInfosModalOpen && !isFunnelTvodOpened} />
            </section>
          )}

          <section
            className={cx(
              'detailV5__actionsWrapper',
              `detailV5__actionsWrapper--compact-ratio-${compactImgRatio}`,
              `detailV5__actionsWrapper--regular-ratio-${regularImgRatio}`,
              { 'detailV5__actionsWrapper-immersive': isImmersive }
            )}
          >
            <div className={cx('detailV5__actionsWrapper__content')}>
              {!isTvDevice && hasTrailer && (
                <div className={cx('detailV5__actionsTrailer')}>
                  <VideoActions>
                    <PlayPauseControlButton variant="video" />
                    <SoundControlButton variant="video" />
                    <FullFrameButton variant="video" />
                  </VideoActions>
                </div>
              )}

              <Heading
                title={title}
                link={meta?.canonical}
                cover={cover}
                isTvDevice={isTvDevice}
                isLoggedIn={isLoggedIn}
                ref={mainTitleRef}
                id="immersive-title"
              />

              <Binder
                middleware={MIDDLEWARE_ACTION_LAYOUT}
                className={cx('detailV5__actionsInformationsWrapper', 'actionLayout')}
              >
                <div className={cx('detailV5__actionLayout')}>
                  {(editorialTitle || technicalInfos) && (
                    <div
                      className={cx('detailV5__editorialWrapper', {
                        'detailV5__editorialWrapper--fadeIn': shouldFadeIn,
                      })}
                    >
                      <div className={cx('detailV5__editorialAndTechnical')}>
                        {editorialTitle && <EditorialTitle title={editorialTitle} />}
                        {technicalInfos && (
                          <TechnicalInfos
                            technicalInfos={{ ...technicalInfos, parentalRatings: parentalRatingsWithTitles }}
                            options={{
                              closedCaptioningText: t('Accessibility.closedCaptioning'),
                              audioDescriptionText: t('Accessibility.audioDescription'),
                            }}
                          />
                        )}
                      </div>
                      {URLLogoChannel && (
                        <div className={cx('detailV5__actionsURLLogo', 'detailV5__URLLogo--actionsLogo')}>
                          <URLLogo URLLogoChannel={URLLogoChannel} />
                        </div>
                      )}
                    </div>
                  )}
                  <ActionLayout
                    actionLayout={actionLayout}
                    from={from}
                    onFocusable={onFocusable}
                    scrollToTab={scrollToTab}
                    toggleActionLayoutLoading={toggleActionLayoutLoading}
                  />
                </div>
                <div className={cx('detailV5__informations', { 'detailV5__informations--fadeIn': shouldFadeIn })}>
                  {URLLogoChannel && (
                    <div className={cx('detailV5__URLLogo')}>
                      <URLLogo URLLogoChannel={URLLogoChannel} />
                    </div>
                  )}
                  {!!summary?.text && <Summary text={summary.text} prefix={summary.prefix} />}
                  <div className={cx('detailV5__metadatas')}>
                    <div className={cx('detailV5__metadatas__left')}>
                      <div className={cx('detailV5__metadatas__left__textList')}>
                        {!!personalities?.length && !!technicalInfos?.parentalRatings && (
                          <Metadatas metadatas={mapStaticKey(personalities, 'prefix')} />
                        )}
                        {!!productionNationalities?.length && (
                          <Metadatas metadatas={mapStaticKey(productionNationalities, 'prefix')} />
                        )}
                      </div>
                      {!!reviews?.length && <Reviews reviews={mapStaticKey(reviews, ['name', 'rating'])} />}
                    </div>

                    {moreInfos?.label && (
                      <MoreInfosButton
                        path={moreInfos.path}
                        label={moreInfos.label}
                        handleOpenMoreInfo={handleOpenMoreInfo}
                      />
                    )}
                  </div>
                </div>
              </Binder>
            </div>
          </section>
        </VideoProvider>
      </header>
      {!!deviceSpecificTabs.length && (
        <Tabs
          from={from}
          isTvDevice={isTvDevice}
          isImmersive={isImmersive}
          isActionLayoutLoading={isActionLayoutLoading}
          tabs={deviceSpecificTabs}
          tabIndexToScroll={tabIndexToScroll}
          setTabIndexToScroll={setTabIndexToScroll}
        />
      )}
      {isMoreInfosModalOpen && moreInfos?.URLPage && (
        <MoreInfosModal from={from} setOpenMoreInfosModal={setOpenMoreInfosModal} URLPage={moreInfos.URLPage} />
      )}
    </div>
  );
}

export default DetailV5;
