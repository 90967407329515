import { HodorSdkConfig, Template } from '@canalplus/sdk-hodor';
import { ApiV2DetailTab } from '@dce-front/hodor-types/api/v2/detail/spyro/definitions';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { Dispatch, SetStateAction, useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { FromProp } from '../../../../server/modules/fetchWithQuery/types';
import { hodorSdkConfigSelector } from '../../../../store/slices/application-selectors';
import { displayTVModeSelector } from '../../../../store/slices/displayMode-selectors';
import { fetchContentGrid } from '../../../../templates/ContentGrid/data/fetchContentGrid';
import { fetchContentGridHighlights } from '../../../../templates/ContentGridHighlights/data/fetchContentGridHighlights';
import { fetchLanding } from '../../../../templates/LandingV5/data/fetchLanding';
import { fetchSportVitrine } from '../../../../templates/SportVitrine/data/fetchSportVitrine';
import { fetchMoreInfos } from '../MoreInfos/data/fetchMoreInfos';

const PREFETCH_TIMEOUT = 3000;

type PrefetchDetailTabsParameters = {
  queryClient: QueryClient;
  tabs: ApiV2DetailTab[];
  setIsFetched: Dispatch<SetStateAction<boolean>>;
  isTvDevice: boolean;
  path: string;
  hodorSdkConfig: HodorSdkConfig;
} & FromProp;

const prefetchDetailTabs = ({
  from,
  queryClient,
  tabs,
  setIsFetched,
  isTvDevice,
  path,
  hodorSdkConfig,
}: PrefetchDetailTabsParameters) => {
  tabs.forEach(async (tab, index) => {
    if (index > 0) {
      const { displayTemplate, URLPage, parameters: onClickParameters } = tab;

      if (URLPage) {
        switch (displayTemplate) {
          case Template.ContentGrid:
            await fetchContentGrid({ from, queryClient, URLPage, hodorSdkConfig, onClickParameters });
            break;

          case Template.Highlights:
            await fetchContentGridHighlights({ from, queryClient, URLPage, hodorSdkConfig, onClickParameters });
            break;

          case Template.Landing:
            await fetchLanding({ from, queryClient, URLPage, isTvDevice, hodorSdkConfig, onClickParameters });
            break;

          case Template.MoreInfos:
            await fetchMoreInfos({ from, queryClient, URLPage, path, hodorSdkConfig, onClickParameters });
            break;

          case Template.SportVitrine:
            await fetchSportVitrine({ from, queryClient, URLPage, hodorSdkConfig, onClickParameters });
            break;

          default:
            break;
        }
      }
    }
  });

  setIsFetched(true);
};

type UsePrefetchDetailTabsArgs = {
  path: string;
  tabs: ApiV2DetailTab[];
} & FromProp;

export const usePrefetchDetailTabs = ({ from, path, tabs }: UsePrefetchDetailTabsArgs): void => {
  const queryClient = useQueryClient();
  const [isFetched, setIsFetched] = useState(false);

  const isTvDevice = useSelector(displayTVModeSelector);
  const hodorSdkConfig = useSelector(hodorSdkConfigSelector);

  useEffect(() => {
    if (!isTvDevice || isFetched || !hodorSdkConfig) {
      return;
    }

    const timeout = setTimeout(
      () => prefetchDetailTabs({ from, queryClient, tabs, setIsFetched, isTvDevice, path, hodorSdkConfig }),
      PREFETCH_TIMEOUT
    );

    return () => {
      clearTimeout(timeout);
    };
  }, [isFetched, isTvDevice, tabs]); // eslint-disable-line react-hooks/exhaustive-deps
};

/**
 * Listens to immersive / window scroll events to toggle tabs content rendering. Always true for non-tv device
 *
 * @returns A boolean set to true if tab content should be mounted, false otherwise
 */
export function useShowTabsContent(isImmersive: boolean = true): boolean {
  const isTvDevice = useSelector(displayTVModeSelector);
  const [showTabContent, setShowTabContent] = useReducer(() => true, !isTvDevice);

  useEffect(() => {
    const scrollContainer = isImmersive ? document.getElementById('immersive') : window;

    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', setShowTabContent);

      return () => {
        scrollContainer.removeEventListener('scroll', setShowTabContent);
      };
    }

    return undefined;
  }, [setShowTabContent, isImmersive]);

  return showTabContent;
}
