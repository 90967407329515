import { requestApi } from '@canalplus/sdk-hodor';
import { isAppKey } from '../../config/application/types';
import { PERSO_HODOR_API_PARAMETERS } from '../constants/hodor';
import Logger from '../helpers/logger/logger-helper';
import { getListUrl } from '../helpers/url/url-helper';
import { hodorSdkConfigSelector, tokenCMSSelector } from '../store/slices/application-selectors';

export type UserListActions = 'add' | 'remove';

/**
 * Add or remove content to the user list
 *
 * @param action     add or remove
 * @param contentIds array of contentID
 * @param listType   listType of the Card
 * @param callback   optional callback function
 */
export const userListService =
  (action: UserListActions, contentIds: string[], listType: string, callback?: () => void): Redux.ThunkAction<void> =>
  async (_dispatch, getState) => {
    const state = getState();
    const hodorSdkConfig = hodorSdkConfigSelector(state);
    const cmsToken = tokenCMSSelector(state);

    if (!hodorSdkConfig) {
      Logger.error('[USER_LIST_SERVICE] hodorSdkConfig is undefined');
      return;
    }

    if (!isAppKey(hodorSdkConfig.appKey)) {
      Logger.error('[USER_LIST_SERVICE] hodorSdkConfig.appKey is not allowed');
      return;
    }

    await requestApi(hodorSdkConfig, {
      apiName: 'userList',
      body: JSON.stringify({ contentIds }),
      method: action === 'add' ? 'PUT' : 'DELETE',
      hodorApiParameter: PERSO_HODOR_API_PARAMETERS,
      url: getListUrl(cmsToken, listType, hodorSdkConfig.appKey),
    });

    callback?.();
  };
